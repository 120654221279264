<template>
    <div class="container relative width_100_percent height_100">
        <Card class="ce box">
            <div slot="title" class="text_align_center title"><h3>Kinglong琪朗后台管理系统</h3></div>
            <Input clearable type="text" prefix="md-person" placeholder="请输入用户名" class="input" v-model.trim="user_name" />
            <Input password type="password" prefix="md-lock" placeholder="请输入密码" class="input" v-model.trim="password" @on-enter="onLogin" />
            <Button type="primary" class="btn_login" @click="onLogin">登录</Button>
        </Card>
        <mySpin :loading="ajaxLoading"></mySpin>
    </div>
</template>
<script>
import { loginout, login, user } from '@/lib/request/auth';
import { localStorageKey } from '@/lib/localStorageKey';
import localstorage from '@/lib/localstorage';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            user_name: null,
            password: null,
        };
    },
    methods: {
        ...mapActions(['setPerms', 'setUserInfo']),
        //登录操作
        onLogin() {
            let { user_name, password } = this;
            let vs = [
                { value: user_name, tip: '请输入用户名' },
                { value: password, tip: '请输入密码' },
            ];

            hm.validateForm(vs)
                .then(() => {
                    this.ajaxLoading = true;
                    loginout()
                        .then(() => {})
                        .finally(() => {
                            this.ajaxLoading = true;
                            localstorage.clear();
                            login({
                                userName: user_name,
                                passWord: password,
                            })
                                .then(res => {
                                    let data = res.data || {};
                                    let access_token = data.token || '';
                                    let expires_at = (new Date().getTime() + 3 * 60 * 60 * 1000) / 1000;
                                    localstorage.set(localStorageKey.ACCESS_TOKEN_QILANG, access_token);
                                    localstorage.set(localStorageKey.EXPIRES_AT_QILANG, expires_at);

                                    user().then(res => {
                                        let { flagarr, groupid, id, nickName, userImg, userName } = res.data || {};
                                        let perms = JSON.parse(flagarr);
                                        // console.log('########## login onLogin success permisions = ' + perms.join(','));

                                        this.setPerms(perms);
                                        this.setUserInfo({
                                            groupid,
                                            id,
                                            nickName,
                                            userImg,
                                            userName,
                                        });
                                        this.goUrl('/index');
                                    });
                                    // this.goUrl('/index');
                                    // // this.$router.push('/orderManager');
                                })
                                .finally(() => {
                                    this.ajaxLoading = false;
                                });
                        });
                })
                .catch(msg => {
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.container {
    // background-color: rgba(245, 154, 35, 0.5);
    background-image: linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);

    .box {
        width: 450px;
        height: 350px;
        padding: 30px;
        .title {
            color: #9890e3;
        }
        .input {
            height: 42px;
            & /deep/ .ivu-input {
                height: 42px;
                line-height: 42px;
                font-size: 16px;
            }
            & /deep/ .ivu-input-prefix i,
            & /deep/ .ivu-input-suffix i {
                font-size: 20px;
                line-height: 42px;
            }
            & ~ .input {
                margin-top: 20px;
            }
        }
        .btn_login {
            width: 100%;
            height: 40px;
            display: block;
            margin: 20px auto 0;
        }
    }
}
</style>
